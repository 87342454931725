import { Emoji } from "frontend/components/Emoji";


interface RankMojiProps{
	rank?: number;
	useFirstBlood?: boolean;
	useFullRank?: boolean;
	isPerfectSolve?: boolean;
}
export function RankMoji({ rank, useFirstBlood = false, useFullRank = false, isPerfectSolve = false }: RankMojiProps) {
	switch(rank) {
		case undefined: return <Emoji>💤</Emoji>;
		case 1: return useFirstBlood && isPerfectSolve
			? <Emoji>🩸</Emoji>
			: <Emoji>🥇</Emoji>;
		case 2: return <Emoji>🥈</Emoji>;
		case 3: return <Emoji>🥉</Emoji>;
		case 4: return <Emoji>4️⃣</Emoji>;
		case 5: return <Emoji>5️⃣</Emoji>;
		case 6: return <Emoji>6️⃣</Emoji>;
		case 7: return <Emoji>7️⃣</Emoji>;
		case 8: return <Emoji>8️⃣</Emoji>;
		case 9: return <Emoji>9️⃣</Emoji>;
		default: return useFullRank ? <span>{rank}</span> : <Emoji>🚩</Emoji>;
	}
}
