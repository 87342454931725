import * as ReactDOM from "react-dom";

import { App } from "frontend/App";

const root = document.querySelector("#root");
if (!root) {
	throw "Misconfigured HTML document: root element missing!";
}

ReactDOM.render(
	<App />,
	document.querySelector("#root"),
);
