import { makeStyles } from "@material-ui/core";

interface EmojiProps {
	children?: React.ReactNode;
} 
export function Emoji({ children }: EmojiProps) {
	const classes = useStyles();
	return <span role="img" className={classes.emoji}>{children}</span>;
}

const useStyles = makeStyles({
	emoji: {
		fontSize: 26,
		transform: "scale(0.75)",
	},
});
