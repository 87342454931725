import { Button, ExtendButtonBase, IconButton, SvgIcon } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import MenuItem, { MenuItemProps } from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useEffect, useRef, useState, Children, KeyboardEvent, MouseEvent, PropsWithChildren, ReactElement } from "react";

interface ContextMenuProps  {
	buttonTitle?: string;
	ButtonIcon?: typeof SvgIcon;
	buttonIconProps?: any;
}
export const ContextMenu = ({ buttonTitle, ButtonIcon = MoreVertIcon, buttonIconProps, children }: PropsWithChildren<ContextMenuProps>) => {
	const classes = useStyles();
	const [ open, setOpen ] = useState(false);
	const anchorRef = useRef<HTMLButtonElement>(null);

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event: MouseEvent<EventTarget>) => {
		if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
			return;
		}

		setOpen(false);
	};

	const handleChildClick = (onClick?: ((e: MouseEvent<HTMLLIElement>) => void)) => (event: MouseEvent<HTMLLIElement>) => {
		handleClose(event);
		onClick?.(event);
	};

	function handleListKeyDown(event: KeyboardEvent) {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpen(false);
		}
	}

	const prevOpen = useRef(open);
	useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current!.focus();
		}

		prevOpen.current = open;
	}, [ open ]);

	const MenuButton: ExtendButtonBase<any> = buttonTitle
		? Button
		: IconButton
	;
	const content = buttonTitle
		? buttonTitle
		: <ButtonIcon {...buttonIconProps} />
;

	return (
		<div className={classes.root}>
			<div>
				<MenuButton
					id="main-nav"
					ref={anchorRef}
					aria-controls={open ? "menu-list-grow" : undefined}
					aria-haspopup="true"
					onClick={handleToggle}
				>
					{content}
				</MenuButton>
				<Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{ zIndex: 999 }}>
					{({ TransitionProps, placement }) => (
						<Grow
							{...TransitionProps}
							style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
						>
							<Paper>
								<ClickAwayListener onClickAway={handleClose}>
									<MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
										{Children.map(children, (child) => {
											const item = child as ReactElement<PropsWithChildren<MenuItemProps>>;
											if (item.type !== MenuItem) {
												return child;
											}
											return <MenuItem onClick={handleChildClick(item.props.onClick)}>{item.props?.children}</MenuItem>;
										})}
									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
			</div>
		</div>
	);
};


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: { display: "flex" },
		paper: { marginRight: theme.spacing(2) },
	}),
);
