export * from "./percentages";

export function roundToDecimals(value: number, decimals: number) {
	const factor = 1 / Math.pow(10, decimals);
	return roundToLot(value, factor);
}

export enum RoundingMode {
	ARITHMETIC,
	UP,
	DOWN
}

export function roundToLot(value: number, lotSize: number, mode: RoundingMode = RoundingMode.ARITHMETIC) {
	switch (mode) {
		case RoundingMode.ARITHMETIC: return Math.round(value / lotSize) * lotSize;
		case RoundingMode.UP: return Math.ceil(value / lotSize) * lotSize;
		case RoundingMode.DOWN: return Math.floor(value / lotSize) * lotSize;
	}
}
