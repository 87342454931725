const MILLIS_IN_SECOND = 1000;
const MILLIS_IN_MINUTE = 60 * MILLIS_IN_SECOND;
const MILLIS_IN_HOUR = 60 * MILLIS_IN_MINUTE;
const MILLIS_IN_DAY = 24 * MILLIS_IN_HOUR;

export function formatTimeExpression(millis: number): string {
	if (millis < 0) {
		return `-${formatTimeExpression(-millis)}`;
	}
	const days = Math.floor(millis / MILLIS_IN_DAY);
	const hours   = Math.floor((millis - days * MILLIS_IN_DAY) / MILLIS_IN_HOUR);
	const minutes = Math.floor((millis - days * MILLIS_IN_DAY - hours * MILLIS_IN_HOUR) / MILLIS_IN_MINUTE);
	const seconds = Math.floor((millis - days * MILLIS_IN_DAY - hours * MILLIS_IN_HOUR - minutes * MILLIS_IN_MINUTE) / MILLIS_IN_SECOND);

	if (days) {
		return `${days} days, ${formatTime(hours, minutes, seconds)}`;
	}
	return formatTime(hours, minutes, seconds);
}

function formatTime(hours: number, minutes: number, seconds: number) {
	return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
}
