import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { Ranking } from "common/model/ranking";

export const rankingApi = createApi({
	reducerPath: "rankingApi",
	baseQuery: fetchBaseQuery({ baseUrl: "/api/" }),
	tagTypes: [],
	endpoints: (builder) => ({ getRanking: builder.query<Ranking, void>({ query: () => `rankings/${location.hostname}` }) }),
});

export const { useGetRankingQuery } = rankingApi;
