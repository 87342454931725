import { roundToLot } from "@hlcr/core/numeric";
import { formatTimeExpression } from "@hlcr/core/time";
import { Breadcrumbs, Container, Link, List, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { DataGrid, GridColumns, GridRenderCellParams, GridValueFormatterParams, GridValueGetterParams } from "@mui/x-data-grid";
import { useState } from "react";
import { useParams } from "react-router";
import { Link as RouterLink } from "react-router-dom";

import { EventUnitRankingBaseDto } from "common/model/ranking";
import { LevelMoji, LevelText } from "frontend/components/LevelMoji";
import { RankMoji } from "frontend/components/RankMoji";
import { useGetRankingQuery } from "frontend/data/ranking";


interface TaskDetailsParams {
	taskId: string;
}
export const IndividualsTask = () => {
	const { taskId } = useParams<TaskDetailsParams>();
	const classes = useStyles();
	const [ pageSize, setPageSize ] = useState(25);
	const { isLoading, data: rankingData } = useGetRankingQuery();

	if (isLoading) {
		return <div>Loading...</div>;
	}

	if (!rankingData) {
		return <div>An error has occurred ;-(</div>; // FIXME
	}

	const rankingUnit = rankingData.unitRanking.find(unit => unit.id === Number.parseInt(taskId));
	if(!rankingUnit) {
		return <div>Unknown...</div>;
	}

	const individualRankings = rankingUnit.individualRankings.filter(ranking => ranking.points && ranking.points > 0);

	return <Container maxWidth={"md"}>
		<Breadcrumbs aria-label="breadcrumb" separator=">">
			<Link underline="hover" color="inherit" to="/individuals" component={RouterLink}>Individuals Ranking</Link>
			<Typography color="primary">{rankingUnit.title}</Typography>
		</Breadcrumbs>

		<h1>{rankingUnit.title}</h1>

		<List dense={true} className={classes.list}>
			<ListItem>
				<ListItemIcon>⏱</ListItemIcon> {/* TODO: TimeMoji, half hour rounding */}
				<ListItemText primary={new Date(rankingUnit.startTime).toLocaleString()} secondary="Published" />
			</ListItem>
			{/* <ListItem>*/}
			{/*	<ListItemIcon>⌛</ListItemIcon>*/}
			{/*	<ListItemText primary={details.averageSolveTime ? formatTimeExpression(details.averageSolveTime): "n/a"} secondary="Average Time" />*/}
			{/* </ListItem>*/}
			<ListItem>
				<ListItemIcon><LevelMoji level={rankingUnit.level.id} /></ListItemIcon>
				<ListItemText primary={<LevelText level={rankingUnit.level.id} />} secondary={`Original: ${rankingUnit.level.name}`} />
			</ListItem>
		</List>

		<DataGrid
			className={classes.table}
			loading={isLoading}
			columns={getColumns()}
			getRowId={(r) => r.userId}
			rows={individualRankings}
			rowsPerPageOptions={[ 10,25,100 ]}
			pageSize={pageSize}
			onPageSizeChange={setPageSize}
			disableSelectionOnClick={true}
		/>
	</Container>;
};

const getColumns = (): GridColumns => [
	{
		headerName: "Rank",
		headerAlign: "center",
		field: "rank",
		width: 75,
		align: "center",
		sortable: false,
		filterable: false,
		disableColumnMenu: true,
		renderCell: (params: GridRenderCellParams) => <RankMoji rank={params.value as number} useFirstBlood={true} isPerfectSolve={(params.row as EventUnitRankingBaseDto).perfectSolve} useFullRank={true} />,
	},
	{
		headerName: "Score",
		headerAlign: "center",
		field: "points",
		valueGetter: (params: GridValueGetterParams) => roundToLot(params.value as number, 1),
		width: 75,
		align: "center",
		sortable: false,
		filterable: false,
		disableColumnMenu: true,
	},
	{
		headerName: "Nickname",
		field: "userName",
		minWidth: 200,
		flex: 1,
		sortable: true,
		filterable: false,
		disableColumnMenu: true,
		renderCell: (params: GridRenderCellParams) => <Link underline="hover" color="inherit" to={`/individuals/${params.value}`} component={RouterLink}>{params.value}</Link>,
	},
	{
		headerName: "Time Difference",
		headerAlign: "right",
		field: "offsetInSeconds",
		valueFormatter: (params: GridValueFormatterParams) => params.value ? formatTimeExpression(params.value as number * 1000) : "-",
		width: 150,
		align: "right",
		sortable: false,
		filterable: false,
		disableColumnMenu: true,
	},
	{
		headerName: "Solution Time",
		headerAlign: "right",
		field: "lastScoredAt",
		valueFormatter: (params: GridValueFormatterParams) => new Date(params.value as string).toLocaleString(),
		width: 170,
		align: "right",
		sortable: false,
		filterable: false,
		disableColumnMenu: true,
	},
];


const useStyles = makeStyles({
	list: {
		display: "flex",
		flexDirection: "row",
		padding: 0,
	},
	table: { height: "calc(100vh - 295px)", marginTop: 20 },
});
