import { Provider } from "react-redux";
import { Redirect } from "react-router";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { store } from "./data/store";
import { Layout } from "./layout";
import { routes } from "./pages";


export const App = () => (
	<Provider store={store}>
		<Router>
			<Switch>
				{
					routes.map(
						route => route.component
							? <Route key={route.path} component={Layout(route.component)} path={route.path} exact={!!route.exact} />
							: <Route key={route.path} exact={true} path={route.path}><Redirect to={route.redirectTo!} /></Route>,
					)
				}
			</Switch>
		</Router>
	</Provider>
);
