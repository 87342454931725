import { Card, CardContent, CardHeader, Container, Grid, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import { LevelMoji } from "frontend/components/LevelMoji";
import { RankMoji } from "frontend/components/RankMoji";


export const Legend = () => {
	const classes = useStyles();

	return <Container maxWidth={"md"}>
		{/* <Breadcrumbs aria-label="breadcrumb" separator=">">
			<Link underline="hover" color="inherit" to="/" component={RouterLink}>Ranking</Link>
			<Typography color="primary">{details.title}</Typography>
		</Breadcrumbs> */}

		<Grid container={true} spacing={2}>
			<Grid item={true} xs={12} sm={6}>
				<Card title="Difficulty">
					<CardHeader title="Difficulty" />
					<CardContent>
						<List dense={true} className={classes.list}>
							<ListItem>
								<ListItemIcon><LevelMoji level={1} /></ListItemIcon>
								<ListItemText primary="Novice" />
							</ListItem>
							<ListItem>
								<ListItemIcon><LevelMoji level={2} /></ListItemIcon>
								<ListItemText primary="Easy" />
							</ListItem>
							<ListItem>
								<ListItemIcon><LevelMoji level={3} /></ListItemIcon>
								<ListItemText primary="Medium" />
							</ListItem>
							<ListItem>
								<ListItemIcon><LevelMoji level={4} /></ListItemIcon>
								<ListItemText primary="Hard" />
							</ListItem>
							<ListItem>
								<ListItemIcon><LevelMoji level={5} /></ListItemIcon>
								<ListItemText primary="Leet" />
							</ListItem>
						</List>
					</CardContent>
				</Card>
			</Grid>
			<Grid item={true} xs={12} sm={6}>
				<Card title="Ranking">
					<CardHeader title="Ranking" />
					<CardContent>
						<List dense={true} className={classes.list}>
							<ListItem>
								<ListItemIcon><RankMoji rank={1} useFirstBlood={true} isPerfectSolve={true} /></ListItemIcon>
								<ListItemText primary="First blood" secondary="Per Task" />
							</ListItem>
							<ListItem>
								<ListItemIcon><RankMoji rank={1} useFirstBlood={false} /></ListItemIcon>
								<ListItemText primary="Top ranked" secondary="Overall" />
							</ListItem>
							<ListItem>
								<ListItemIcon><RankMoji rank={2} useFirstBlood={false} /></ListItemIcon>
								<ListItemText primary="Second" />
							</ListItem>
							<ListItem>
								<ListItemIcon><RankMoji rank={3} useFirstBlood={false} /></ListItemIcon>
								<ListItemText primary="Third" />
							</ListItem>
							<ListItem>
								<ListItemIcon><RankMoji rank={4} /></ListItemIcon>
								<ListItemText primary="Fourth" secondary="Ranks up to 9" />
							</ListItem>
							<ListItem>
								<ListItemIcon><RankMoji rank={99} useFullRank={false} /></ListItemIcon>
								<ListItemText primary="Ranks > 9" />
							</ListItem>
							<ListItem>
								<ListItemIcon><RankMoji rank={undefined} /></ListItemIcon>
								<ListItemText primary="Not yet solved" />
							</ListItem>
						</List>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	</Container>;
};

const useStyles = makeStyles({
	list: {
		display: "flex",
		flexDirection: "column",
		padding: 0,
	},
});
