import { roundToLot } from "@hlcr/core/numeric";
import { Breadcrumbs, Container, Link, List, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { DataGrid, GridCellParams, GridColumns, GridValueFormatterParams, GridValueGetterParams } from "@mui/x-data-grid";
import { useParams } from "react-router";
import { Link as RouterLink } from "react-router-dom";

import { AssetDifficultyLevel } from "backend/model/event";
import { IndividualRanking, SolvedChallenge } from "common/model/participation";
import { RankingEventUnitDto } from "common/model/ranking";
import { LevelMoji } from "frontend/components/LevelMoji";
import { RankMoji } from "frontend/components/RankMoji";
import { useGetRankingQuery } from "frontend/data/ranking";


interface ContestantDetailsParams {
	contestantId: string;
}

export const IndividualContestant = () => {
	const { contestantId } = useParams<ContestantDetailsParams>();
	const classes = useStyles();
	const { isLoading, data: rankingData } = useGetRankingQuery();

	if (isLoading) {
		return <div>Loading...</div>;
	}

	if (!rankingData) {
		return <div>An error has occurred ;-(</div>; // FIXME
	}

	const ir: IndividualRanking | undefined = rankingData.individualRanking.find(s => s.username === contestantId);
	if (!ir) {
		return <div>Unknown Contestant...</div>;
	}

	const solvedChallenges: SolvedChallenge[] = ir.challenges.filter(ch => ch.points > 0)
		.sort((a,b) => Date.parse(a.lastUserSolutionTime) - Date.parse(b.lastUserSolutionTime))
		.map(sc => {
			const unitRanking: RankingEventUnitDto | undefined = rankingData.unitRanking.find(u => u.id === sc.id);
			const individualRank = unitRanking?.individualRankings.find(i => i.userName === ir.username);

			if (unitRanking && individualRank) {
				return {
					...sc,
					level: unitRanking.level,
					title: unitRanking.title,
					rank: individualRank.rank,
					perfectSolve: individualRank.perfectSolve,
				};
			}
			return sc;
		});

	return <Container maxWidth={"md"}>
		<Breadcrumbs aria-label="breadcrumb" separator=">">
			<Link underline="hover" color="inherit" to="/individuals" component={RouterLink}>Individuals Ranking</Link>
			<Typography color="primary">{contestantId}</Typography>
		</Breadcrumbs>

		<h1 className={classes.header}>{contestantId}</h1>

		<List dense={true} className={classes.list}>
			<ListItem>
				<ListItemIcon><RankMoji rank={ir.rank} /></ListItemIcon>
				<ListItemText primary={ir.rank} secondary="Rank" />
			</ListItem>
			<ListItem>
				<ListItemIcon>🧾</ListItemIcon>
				<ListItemText primary={roundToLot(ir.points, 1)} secondary="Score" />
			</ListItem>
			{/* Average Solve Time is depending on Scoring Mode */}
			{/* <ListItem>*/}
			{/*	<ListItemIcon>⌛</ListItemIcon>*/}
			{/* <ListItemText primary={solveTime ? formatTimeExpression(solveTime) : "n/a"} secondary="Average Time" />*/}
			{/* </ListItem>*/}
		</List>

		<DataGrid
			className={classes.table}
			loading={isLoading}
			columns={getColumns()}
			getRowId={solvedChallenge => (solvedChallenge as SolvedChallenge).id}
			rows={solvedChallenges}
		/>
	</Container>;
};

const getColumns = (): GridColumns => [
	{
		headerName: "Task",
		field: "title",
		minWidth: 200,
		flex: 1,
		sortable: true,
		filterable: false,
		disableColumnMenu: true,
		renderCell: (params: GridCellParams) => <Link underline="hover" color="inherit" to={`/individuals/tasks/${(params.row as SolvedChallenge).id}`} component={RouterLink}>{params.value}</Link>,
	},
	{
		headerName: "Rank",
		headerAlign: "center",
		field: "rank",
		renderCell: (params: GridCellParams) => <RankMoji rank={params.value as number} useFirstBlood={true} isPerfectSolve={(params.row as SolvedChallenge).perfectSolve} useFullRank={true} />,
		width: 100,
		align: "center",
		sortable: false,
		filterable: false,
		disableColumnMenu: true,
	},
	{
		headerName: "Score",
		headerAlign: "center",
		field: "points",
		valueGetter: (params: GridValueGetterParams) => roundToLot(params.value as number, 1),
		width: 100,
		align: "center",
		sortable: false,
		filterable: false,
		disableColumnMenu: true,
	},
	{
		headerName: "Difficulty",
		headerAlign: "center",
		field: "level",
		renderCell: (params: GridCellParams) => <LevelMoji level={(params.value as AssetDifficultyLevel).id} />,
		width: 100,
		align: "center",
		sortable: false,
		filterable: false,
		disableColumnMenu: true,
	},
	{
		headerName: "Solution Time",
		headerAlign: "center",
		field: "lastUserSolutionTime",
		valueFormatter: (params: GridValueFormatterParams) => params.value ? new Date(params.value as string).toLocaleString() : "Unsolved",
		width: 250,
		align: "center",
		sortable: false,
		filterable: false,
		disableColumnMenu: true,
	},
];


const useStyles = makeStyles({
	list: {
		display: "flex",
		flexDirection: "row",
		padding: 0,
	},
	table: { height: "calc(100vh - 285px)", marginTop: 20 },
	header: { marginBottom: 5, padding: "0!important" },
});
