import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum RequestStates {
	INITIAL,
	STARTED,
	ERROR,
	SUCCESS,
}
interface SettingsState {
	pollingInterval: number;
	isFrozen: boolean;
}
const settingsSlice = createSlice({
	name: "settings",
	initialState: { pollingInterval: 300000, isFrozen: false } as SettingsState,
	reducers: {
		setPollingInterval: (state: SettingsState, action: PayloadAction<number>) => {
			state.pollingInterval = action.payload * 1000;
		},
		setIsFrozen: (state: SettingsState, action: PayloadAction<boolean>) => {
			state.isFrozen = action.payload;
		},
	},
});
export const settingsReducer = settingsSlice.reducer;
export const { setPollingInterval, setIsFrozen } = settingsSlice.actions;
