import { IndividualContestant } from "frontend/pages/IndividualContestant";
import { IndividualsRanking } from "frontend/pages/IndividualsRanking";
import { IndividualsTask } from "frontend/pages/IndividualsTask";
import { TeamContestant } from "frontend/pages/TeamContestant";
import { TeamsRanking } from "frontend/pages/TeamsRanking";
import { TeamTask } from "frontend/pages/TeamTask";

import { Legend } from "./Legend";

type Route<T> = ComponentRoute<T> | RedirectRoute<T>;

interface BaseRoute<T> {
	title?: string;
	path: string;
	hidden?: boolean;
	exact?: boolean;
	redirectTo?: string;
	component?: (props: T) => JSX.Element;
}

export interface ComponentRoute<T> extends BaseRoute<T> {
	title: string;
	component: (props: T) => JSX.Element;
	redirectTo?: undefined;
}

interface RedirectRoute<T> extends BaseRoute<T> {
	redirectTo: string;
	hidden: true;
	exact: true;
}


export const routes: Array<Route<any>> = [
	{ path: "/", redirectTo: "/individuals", hidden: true, exact: true },
	{ path: "/individuals", title: "Individuals", component: IndividualsRanking, exact: true },
	{ path: "/individuals/tasks/:taskId", title: "Individuals Task", component: IndividualsTask, hidden: true },
	{ path: "/individuals/:contestantId", title: "Individual Contestant", component: IndividualContestant, hidden: true },
	{ path: "/teams", title: "Teams", component: TeamsRanking, exact: true },
	{ path: "/teams/tasks/:taskId", title: "Team Task", component: TeamTask, hidden: true },
	{ path: "/teams/:contestantId", title: "Team Contestant", component: TeamContestant, hidden: true },
	{ path: "/legend", title: "Legend", component: Legend },
];
