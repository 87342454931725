/* eslint-disable react-hooks/rules-of-hooks -- I wish I knew */
/* eslint-disable react/display-name -- I wish I knew */
import { AppBar, Button, Container, FormControl, InputLabel, makeStyles, MenuItem, Select, Toolbar } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { Alert } from "@material-ui/lab";
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RankingEventUnitDto } from "common/model/ranking";
import { useGetRankingQuery } from "frontend/data/ranking";
import { RootState } from "frontend/data/store";

import { ContextMenu } from "./components/ContextMenu";
import { setIsFrozen, setPollingInterval } from "./data/settings";
import { routes } from "./pages";


export const Layout = <T, >(Page: (pageProps: T) => JSX.Element | null) => (props: T) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const pollingInterval = useSelector((state: RootState) => state.settings.pollingInterval); // is in milliseconds
	const { isLoading, data: rankingData } = useGetRankingQuery(void 0, { pollingInterval });
	const isFrozen = useSelector((state: RootState) => state.settings.isFrozen);

	const handlePollingIntervalChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
		dispatch(setPollingInterval(event.target.value as number));
	};

	useEffect(() => {
		if (rankingData && rankingData.unitRanking?.length > 0) {
			const isFrozenLocal = rankingData.unitRanking.find((ur: RankingEventUnitDto) => ur.rankingFrozen) != undefined;
			if (isFrozenLocal !== isFrozen) {
				dispatch(setIsFrozen(isFrozenLocal));
			}
		}
	});

	return (
		<Fragment>
			<AppBar position="static">
				<Toolbar >
					<Container maxWidth="xl" className={classes.toolbarContainer}>
						<ContextMenu ButtonIcon={MenuIcon} buttonIconProps={{ style: { color: "white" } }}>
							{routes.filter((route) => !route.hidden && route.title).map((route) => <MenuItem key={route.path}><Button href={route.path}>{route.title!}</Button></MenuItem>)}
						</ContextMenu>
						<FormControl fullWidth={false} className={classes.select} >
							<InputLabel id="polling-frequency-select-label" className={classes.white}>Update Interval</InputLabel>
							<Select
								labelId="polling-frequency-select-label"
								id="polling-frequency-select"
								value={pollingInterval / 1000}
								label="Update Interval"
								autoWidth={true}
								className={classes.select}
								inputProps={{
									classes: {
										icon: classes.selectIcon,
										root: classes.white, 
									}, 
								}}
								onChange={handlePollingIntervalChange}
							>
								<MenuItem value={30}>30s</MenuItem>
								<MenuItem value={300}>5m</MenuItem>
								<MenuItem value={1800}>30m</MenuItem>
							</Select>
						</FormControl>
					</Container>
				</Toolbar>
			</AppBar>
			{!isLoading && isFrozen && <Alert severity="info" className={classes.frozenAlert}>Ranking has been frozen!</Alert>}
			<div className={classes?.main}>
				<Page {...props} />
			</div>
		</Fragment>
	);
};

const useStyles = makeStyles((theme) => ({
	main: { marginTop: 16, marginBottom: 16 },
	select: {
		minWidth: 150,
		"&:before": { borderColor: "white" },
		"&:after": { borderColor: "white" },
		"&:not(.Mui-disabled):hover::before": { borderColor: "white" },
	},
	selectIcon: { fill: "white" },
	white: { color: "white" },
	toolbarContainer: {
		color: "red",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
	},
	frozenAlert: {
		width: "calc(100% - 32px)",
		justifyContent: "center",
	},
}));
