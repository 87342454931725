import { roundToLot } from "@hlcr/core/numeric";
import { formatTimeExpression } from "@hlcr/core/time";
import { Container, Link, Tooltip, withStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { DataGrid, GridCellParams, GridColumnHeaderParams, GridColumns, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";

import { IndividualRanking } from "common/model/participation";
import { RankingEventUnitDto } from "common/model/ranking";
import { LevelMoji } from "frontend/components/LevelMoji";
import { RankMoji } from "frontend/components/RankMoji";
import { useGetRankingQuery } from "frontend/data/ranking";
import { RootState } from "frontend/data/store";


export const IndividualsRanking = () => {
	const classes = useStyles();
	const [ pageSize, setPageSize ] = useState(100);
	const { isLoading, data: rankingData } = useGetRankingQuery();
	const isFrozen = useSelector((state: RootState) => state.settings.isFrozen);

	if (!isLoading && !rankingData) {
		return <div>An error has occurred ;-(</div>; // FIXME
	}

	return <Container maxWidth={false}>
		<DataGrid
			className={isFrozen ? classes.tableFrozen : classes.table}
			loading={isLoading}
			columns={getColumns(classes, rankingData?.unitRanking || [])}
			getRowId={(r) => r.username}
			rows={rankingData?.individualRanking || []}
			rowsPerPageOptions={[ 10, 25,100 ]}
			pageSize={pageSize}
			onPageSizeChange={setPageSize}
			disableSelectionOnClick={true}
		/>
	</Container>;
};

const columns: GridColumns  = [
	{
		headerName: "Nickname",
		field: "username",
		width: 200,
		sortable: true,
		filterable: false,
		disableColumnMenu: true,
		renderCell: (params: GridRenderCellParams) => <Link underline="hover" color="inherit" to={`/individuals/${params.value}`} component={RouterLink}>{params.value}</Link>,
	},
	{
		headerName: "Rank",
		headerAlign: "center",
		field: "rank",
		width: 75,
		align: "center",
		sortable: false,
		filterable: false,
		disableColumnMenu: true,
		renderCell: (params: GridRenderCellParams) => <RankMoji rank={params.value as number} useFirstBlood={false} useFullRank={true} />,
	},
	{
		headerName: "Score",
		headerAlign: "center",
		field: "points",
		valueGetter: (params: GridValueGetterParams) => roundToLot(params.value as number, 1),
		width: 75,
		align: "center",
		sortable: false,
		filterable: false,
		disableColumnMenu: true,
	},
	{
		headerName: "Time Difference",
		headerAlign: "right",
		field: "offsetInSeconds",
		valueGetter: (params: GridValueGetterParams) => {
			const offsetInSeconds = params.value as number;
			if(offsetInSeconds === 0) {
				return "";
			}
			return formatTimeExpression(offsetInSeconds * 1000);
		},
		width: 150,
		align: "right",
		sortable: false,
		filterable: false,
		disableColumnMenu: true,
	},
];

function getColumns(classes: ReturnType<typeof useStyles>, units: RankingEventUnitDto[]): GridColumns {
	return [ ...units ]
		.reduce(
			(columns, unit, index): GridColumns => [
				...columns,
				{
					headerName: `${index + 1}`,
					headerAlign: "center",
					renderHeader: (params: GridColumnHeaderParams) => <StyledTooltip title={unit.title.substr(0, 35)} arrow={true} placement="top">
						<Link underline="hover" color="inherit" to={`/individuals/tasks/${unit.id}`} component={RouterLink} className={classes.difficulty}><LevelMoji level={unit.level.id} /></Link>
					</StyledTooltip>,
					field: `${unit.id}`,
					width: 15,
					align: "center",
					sortable: false,
					filterable: false,
					disableColumnMenu: true,
					renderCell: (params: GridCellParams) => {
						const individualRanking = unit.individualRankings.find(r => r.points && r.points > 0 && r.lastScoredAt && r.userName === (params.row as IndividualRanking).username);
						return <RankMoji rank={individualRanking?.rank} useFirstBlood={true} isPerfectSolve={individualRanking?.perfectSolve} />;
					},
				},
			],
			columns,
		)
	;
}


const useStyles = makeStyles({
	table: { height: "calc(100vh - 120px)" },
	tableFrozen: { height: "calc(100vh - 160px)" },
	header: { padding: "0!important" },
	difficulty: {
		"&:hover": {
			height: 50,
			textDecoration: "none!important",
		},
	},
});

const StyledTooltip = withStyles({ tooltipPlacementTop: { top: 16, fontSize: "1em" } })(Tooltip);
