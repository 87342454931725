import { Emoji } from "frontend/components/Emoji";


const NOVICE_CUTOFF = 125;
const EASY_CUTOFF = 250;
const MEDIUM_CUTOFF = 375;
const HARD_CUTOFF = 500;

interface LevelProps {
	level: number;
}
export function LevelMoji({ level }: LevelProps) {
	switch(level) {
		case 1: return <Emoji>🔵</Emoji>;
		case 2: return <Emoji>🟢</Emoji>;
		case 3: return <Emoji>🟡</Emoji>;
		case 4: return <Emoji>🟠</Emoji>;
		case 5: return <Emoji>🔴</Emoji>;
		default: return <Emoji>⚪</Emoji>;
	}
}

export function LevelText({ level }: LevelProps) {
	switch(level) {
		case 1: return <span>Novice</span>;
		case 2: return <span>Easy</span>;
		case 3: return <span>Medium</span>;
		case 4: return <span>Hard</span>;
		case 5: return <span>Leet</span>;
		default: return <span>n/a</span>;
	}
}
