import { configureStore } from "@reduxjs/toolkit";

import { rankingApi } from "frontend/data/ranking";
import { settingsReducer } from "frontend/data/settings";

export const store = configureStore({
	reducer: {
		rankingApi: rankingApi.reducer,
		settings: settingsReducer,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(rankingApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
